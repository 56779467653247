<template>
  <div>
    <SubHeader :hasFilter="true" @search="onSearch">
      <template #title>
        <Title :text="$t('projects.projects')" />
      </template>
    </SubHeader>
    <div>
      <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
        <!-- Project skeleton -->
        <div v-if="$store.state.Projects.loading" class="grid grid-cols-4 gap-3">
          <Skeleton />
        </div>

        <div v-else>
          <!-- Project list empty -->
          <div v-if="!$store.state.Projects.projects.length">
            <EmptyState />
          </div>
          <!-- Project list -->
          <div v-else class="grid grid-cols-4 gap-3">
            <div v-for="project in $store.state.Projects.projects" :key="project.id" @click="selectProject(project.id)">
              <Project :project="project" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from "@/components/App/SubHeader"
import Title from "@/components/App/Title"
import EmptyState from "@/components/Projects/List/EmptyState"
import Skeleton from "@/components/Projects/List/Skeleton"
import Project from "@/components/Projects/List/Project"

export default {
  name: "Projects",
  components: {
    SubHeader,
    Title,
    EmptyState,
    Skeleton,
    Project
  },
  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      filterKeyword: "",
      sort: "-id",
      limit: 50,
      default: {
        sort: "-id",
        limit: 50
      }
    }
  },

  created() {
    this.getProjects()
  },

  methods: {
    onSearch(value) {
      this.filterKeyword = value
      this.getProjects()
    },

    async selectProject(id) {
      if (id == this.$route.params.id) {
        return null
      } else {
        await this.$store.dispatch("Projects/getProject", id)
        if (this.$store.state.Projects.isPremiumProject == 1) {
          this.$router.push({ params: { id }, name: "Dashboard" })
        } else {
          this.$router.push({ params: { id }, name: "Integrations" })
        }
      }
    },

    getProjects() {
      let filter = {}
      if (this.filterKeyword) filter["filter[company][like]"] = `%${this.filterKeyword}%`
      this.$store.dispatch("Projects/getProjects", { filter })
    }
  }
}
</script>
